export const colors = {
  primary: "#0063B2",
  primaryLight: "#40A1E7",
  secondary: "#9CC3D5",
  black: "#333333",
  grey: "#BDBDBD",
  greyText: "#5e5e5e",
  greyLight: "#F5F5F5",
  white: "#FFFFFF",
  background: "#252934",
  darkBackground: "#1D242C",
}
