import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import hamburgerMenuIcon from "../images/hamburger-menu-icon.svg"
import { scrollToId } from "../utilities"
import { colors } from "../styles"

const NavBar = styled.div<{ top: number }>`
  background-color: ${colors.darkBackground};
  position: fixed;
  top: ${props => props.top}px;
  width: 100%;
  transition: top 0.3s;
  z-index: 1100;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: initial;
  }

  a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 15px;
    text-decoration: none;
  }

  a:hover {
    background-color: ${colors.primaryLight};
    color: black;
  }
`

export const NavigationBar = () => {
  const [top, setTop] = useState(-54)
  const { height, width } = useWindowDimensions()
  const [openMenu, setOpenMenu] = useState(false)

  let prevScrollpos: any

  useEffect(() => {
    prevScrollpos = window.pageYOffset

    window.onscroll = function () {
      if (height > window.pageYOffset) {
        setTop(-54)
      } else {
        setTop(0)
      }
    }
    return () => {}
  }, [])

  return (
    <NavBar id="navbar" top={top}>
      {width >= 768 ? (
        <NavBarButtons />
      ) : (
        <>
          <div style={{ display: "flex", margin: "0 10px" }}>
            <img
              src={hamburgerMenuIcon}
              alt="menu"
              onClick={() => {
                setOpenMenu(openMenu => !openMenu)
              }}
              style={{ margin: "5px" }}
            />
          </div>
          {openMenu ? <NavBarButtons setOpenMenu={setOpenMenu} /> : null}
        </>
      )}
    </NavBar>
  )
}
type NavBarButtonsProps = {
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>
}
const NavBarButtons = ({ setOpenMenu }: NavBarButtonsProps) => {
  return (
    <>
      <a
        onClick={() => {
          scrollToId("home")
          if (setOpenMenu) setOpenMenu(false)
        }}
      >
        HOME
      </a>
      <a
        onClick={() => {
          scrollToId("about")
          if (setOpenMenu) setOpenMenu(false)
        }}
      >
        ABOUT
      </a>
      <a
        onClick={() => {
          scrollToId("showcase")
          if (setOpenMenu) setOpenMenu(false)
        }}
      >
        SHOWCASE
      </a>
      <a
        onClick={() => {
          scrollToId("contact")
          if (setOpenMenu) setOpenMenu(false)
        }}
      >
        CONTACT
      </a>
    </>
  )
}

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    // This will only run in the browser where window is defined, so add your event listeners here
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  } else {
    return { width: 0, height: 0 }
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
