import React from "react"
import styled from "styled-components"
import { Background, Spacer } from "../shared"
import linkedinIcon from "../../images/linkedin.svg"
import arrowUpIcon from "../../images/arrow-up.svg"
import { scrollToId } from "../../utilities"
import { colors } from "../../styles"

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;

  font-size: 16px;
  color: ${colors.grey};
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

const LinkIcon = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  margin-bottom: 2em;

  img {
    width: 45px;
    height: 45px;
  }
`

const Square = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  background: ${colors.grey};
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

export const Footer = () => {
  return (
    <Background color={colors.darkBackground}>
      <FooterContainer>
        <Square>
          <img
            src={arrowUpIcon}
            alt="go-up"
            style={{ width: "60%" }}
            onClick={() => {
              scrollToId("home")
            }}
          />
        </Square>
        <Spacer height="3.5em" />
        <LinkIcon href="https://www.linkedin.com/in/mireno-de-giorgio-3b2541135/">
          <img src={linkedinIcon} alt="linkedin" />
        </LinkIcon>
        <div>
          Mireno De Giorgio{" "}
          <span style={{ color: colors.greyText }}>©2021</span>
        </div>
        <Spacer height="3.5em" />
      </FooterContainer>
    </Background>
  )
}
