import React, { useEffect } from "react"
import styled from "styled-components"
import logo from "../../images/logo.svg"
import arrowDown from "../../images/arrow-down.svg"
import { Background } from "../shared"
import { scrollToId } from "../../utilities"
import { motion, useAnimation } from "framer-motion"
import { colors } from "../../styles"
import bg from "../../images/bg.svg"

const WelcomeContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-size: 450px;
  background-attachment: fixed;
  width: 100%;
`

const TextContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1em;
  color: white;
  text-align: center;
  font-size: 11px;
  margin-top: 2em;
  margin-bottom: 2em;
  opacity: 0;

  @media (min-width: 320px) {
    font-size: 13px;
  }
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

const variants = {
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
  bouncing: {
    y: [0, -20, 0],
    transition: {
      duration: 3,
      times: [0, 0.5, 1],
      loop: Infinity,
    },
  },
}

/**
 * Welcome Part of the landing page.
 */
export const Welcome = () => {
  const controls = useAnimation()

  const sequence = async () => {
    await controls.start("visible")
    return await controls.start("bouncing")
  }

  useEffect(() => {
    sequence()

    return () => {}
  }, [])

  return (
    <Background color={colors.background} id="home">
      <WelcomeContainer>
        <motion.img
          variants={variants}
          animate={controls}
          style={{ maxWidth: "80%", opacity: 0 }}
          src={logo}
          alt="logo"
        />
        <TextContainer variants={variants} animate="visible">
          <h3 style={{ margin: "0" }}>
            Hello, I'm{" "}
            <span style={{ color: colors.secondary }}>Mireno De Giorgio</span>
          </h3>
          <h4
            style={{
              lineHeight: "1.5 em",
            }}
          >
            I’m a cloud engineer & full-stack web developer
          </h4>
        </TextContainer>
        <motion.img
          variants={variants}
          animate="visible"
          style={{ maxWidth: "20%", width: "50px", opacity: 0 }}
          whileHover={{ scale: 1.2, transition: { duration: 0.4 } }}
          src={arrowDown}
          alt="arrow-down"
          onClick={() => {
            scrollToId("about")
          }}
        />
      </WelcomeContainer>
    </Background>
  )
}
