import React from "react"
import styled from "styled-components"
import { Spacer } from "./shared"

import { motion } from "framer-motion"
import { useAnimeOnView } from "../hooks"
import { colors } from "../styles"

const Card = styled(motion.div)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid ${colors.grey};
  position: relative;
  margin-bottom: 75px;
  min-width: 297px;
  max-width: 400px;
  font-size: 16px;
  opacity: 0;

  @media (min-width: 320px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

const Circle = styled(motion.div)`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryLight};
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  img {
      height: 45px;
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
`

const SkillText = styled.div`
  margin: 0.5em;
  text-align: center;
  color: #5e5e5e;

  h6 {
    line-height: 1.1em;
    margin: 0px;
  }
`

type Props = {
  title: string
  svgIcon: any
  skills: string[]
}

export const SkillCard = ({ title, svgIcon, skills }: Props) => {
  const [refCard, animateCard] = useAnimeOnView()
  return (
    <Card
      ref={refCard}
      animate={
        animateCard ? { y: [100, 0], opacity: [0, 1] } : { y: 0, opacity: 0 }
      }
      transition={{ duration: 0.5 }}
    >
      <Circle
        animate={
          animateCard
            ? { rotateY: [-180, 0], opacity: [0, 1] }
            : { x: 0, opacity: 0 }
        }
        transition={{ duration: 0.5 }}
      >
        <img src={svgIcon} alt={title} />
      </Circle>
      <Spacer height="45px" />
      <h5 style={{ textAlign: "center", margin: "0.5em 0px" }}>{title}</h5>
      <SkillsGrid>
        {skills.map((skill, i) => (
          <SkillText key={i}>
            <h6>{skill}</h6>
          </SkillText>
        ))}
      </SkillsGrid>
      <Spacer height="45px" />
    </Card>
  )
}
