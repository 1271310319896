import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useAnimeOnView } from "../../hooks"
import { useInView } from "react-intersection-observer"

export const PageTitleStyled = styled(motion.div)`
  grid-area: title;
  align-self: center;
  opacity: 0;

  h3 {
    text-align: center;
    margin: 0.2em;
    font-weight: bold;
    color: #333333;
  }
`

type Props = {
  children: React.ReactNode
}

export const PageTitle = ({ children }: Props) => {
  const [ref, animate] = useAnimeOnView()

  return (
    <PageTitleStyled
      ref={ref}
      animate={
        animate ? { x: [-100, 0], opacity: [0, 1] } : { x: 0, opacity: 0 }
      }
      transition={{ duration: 0.5 }}
    >
      {children}
    </PageTitleStyled>
  )
}
