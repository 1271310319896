import React from "react"
import {
  Welcome,
  About,
  Showcase,
  Contact,
  Footer,
  NavigationBar,
} from "../components"
import { SEO } from "../components"
import { GlobalStyle } from "../styles/GlobalStyle"
import "normalize.css"

export default function Home() {
  return (
    <>
      <GlobalStyle />
      <SEO title="Mireno De Giorgio Personal Website" />
      <NavigationBar />
      <Welcome />
      <About />
      <Showcase />
      <Contact />
      <Footer />
    </>
  )
}
