import React, { useState } from "react"
import styled from "styled-components"
import { Background, PageTitle, Spacer } from "../shared"
import { motion } from "framer-motion"
import { useAnimeOnView } from "../../hooks"
import { colors } from "../../styles"

const InputStyled = styled.input`
  display: block;
  margin: 10px;
  background-color: ${colors.darkBackground};
  font-size: 18px;
  width: 80%;
  color: white;
  padding: 10px;
  border: none;
  max-width: 500px;

  :focus {
    outline: none;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey};
    opacity: 1; /* Firefox */
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

const FormStyled = styled(motion.form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

const Button = styled.button`
  margin-top: 50px;
  background-color: ${colors.darkBackground};
  color: white;
  padding: 10px 32px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline-style: solid;
  outline-color: ${colors.primary};

  cursor: pointer;

  :active {
    outline: none;
    background-color: ${colors.secondary};
  }
`

export const Contact = () => {
  const [email, setEmail] = useState<string | undefined>()
  const [name, setName] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()
  const [ref, animate] = useAnimeOnView()

  const handleOnSubmit = () => {
    const data = {
      name: name,
      email: email,
      message: message,
    }

    fetch(process.env.GATSBY_CONTACT_ME_API!, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log("Success:", data)
        setName("")
        setEmail("")
        setMessage("")
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  return (
    <Background color={colors.background} id="contact">
      <ContactContainer>
        <Spacer height="4em" />
        <PageTitle>
          <h3 style={{ color: colors.white }}>CONTACT</h3>
        </PageTitle>
        <Spacer height="45px" />
        <FormStyled
          ref={ref}
          animate={
            animate ? { y: [100, 0], opacity: [0, 1] } : { y: 0, opacity: 0 }
          }
          transition={{ duration: 0.5 }}
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit()
          }}
        >
          <InputStyled
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Name"
            aria-label="Name"
            aria-required="true"
          />
          <InputStyled
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter email"
            aria-label="Enter email"
            aria-required="true"
          />
          <InputStyled
            as="textarea"
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            placeholder="Message"
            style={{ height: "8em" }}
            data-enable-grammarly="false"
            aria-label="Messsage"
            aria-required="true"
          />
          <Button as="input" type="submit" value="Submit" />
        </FormStyled>
        <Spacer height="4em" />
      </ContactContainer>
    </Background>
  )
}
