import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import { useAnimeOnView } from "../hooks"
import { colors } from "../styles"
import gitHubIcon from "../images/github-icon.svg"
import goToIcon from "../images/goTo-icon.svg"

const ProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4em;
`

const DescriptionContainer = styled(motion.div)`
  max-width: 450px;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 1em;
  opacity: 0;

  h4 {
    margin: 0;
  }
  p {
    text-align: justify;
    text-justify: inter-word;
  }
`

const LinkButton = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  margin-bottom: 1em;
  margin-left: 2em;

  img {
    margin-right: 0.8em;
    width: 30px;
    height: 30px;
  }
  h6 {
    margin: 0;
  }
`

const ImageContainer = styled(motion.div)`
  width: 90%;
  max-width: 450px;
  background: ${colors.secondary};
  opacity: 0;
  a {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  img {
    position: relative;
    top: 10%;
    left: 5%;
    width: 90%;
  }
`

type Props = {
  title: string
  description: string
  gitHubLink: string
  projectLink: string
  image: any
}

export const Project = ({
  title,
  description,
  gitHubLink,
  projectLink,
  image,
}: Props) => {
  const [ref, animate] = useAnimeOnView()

  return (
    <ProjectContainer ref={ref}>
      <DescriptionContainer
        animate={
          animate ? { x: [-100, 0], opacity: [0, 1] } : { x: 0, opacity: 0 }
        }
        transition={{ duration: 0.5 }}
      >
        <h4>{title}</h4>
        <p>{description}</p>
        {/* <LinkButton href={gitHubLink}>
          <img src={gitHubIcon} alt="github" />
          <h6>Code</h6>
        </LinkButton> */}
        <LinkButton href={projectLink} target="_blank">
          <img src={goToIcon} alt={`${title}-image`} />
          <h6>Go to live project</h6>
        </LinkButton>
      </DescriptionContainer>
      <ImageContainer
        animate={
          animate ? { x: [100, 0], opacity: [0, 1] } : { x: 0, opacity: 0 }
        }
        transition={{ duration: 0.5 }}
      >
        <a href={projectLink} target="_blank">
          <img src={image} alt={`${title}-image`} />
        </a>
      </ImageContainer>
    </ProjectContainer>
  )
}
