import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import { useInView } from "react-intersection-observer"

export const useAnimeOnView = (): [
  (node?: Element | null | undefined) => void,
  boolean
] => {
  const [animate, setAnimate] = useState(false)
  const [ref, inView] = useInView({
    rootMargin: "-30% 0px",
  })

  useEffect(() => {
    if (inView && !animate) {
      setAnimate(true)
    }
    return () => {}
  }, [inView])

  return [ref, animate]
}
