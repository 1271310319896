import { createGlobalStyle } from "styled-components"
import { colors } from "./Colors"

export const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${colors.background};
    font-family: "Raleway", sans-serif;
    scroll-behavior: smooth;
  }

  body {
    font-size: 21px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px ${colors.grey}; 
    border-radius: 0px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.primary}; 
    /* border-radius: 10px; */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.secondary}; 
  }

  h1 {
    font-size: 3.052em;
    font-weight: 400;
  }

  h2 {
    font-size: 2.441em;
    font-weight: 400;
  }

  h3 {
    font-size: 1.953em;
    font-weight: 400;
  }

  h4 {
    font-size: 1.563em;
    font-weight: 400;
  }

  h5 {
    font-size: 1.25em;
    font-weight: 400;
  }

  h6 {
    font-size: 1em;
    font-weight: 400;
  }

`
