import React from "react"
import styled from "styled-components"
import { SkillCard } from "../SkillCard"
import { Background, PageTitle, Spacer } from "../shared"

import photo from "../../images/photo.png"
import frontendIcon from "../../images/frontend-icon.svg"
import backendIcon from "../../images/database-icon.svg"
import otherIcon from "../../images/army-knife-icon.svg"
import testingIcon from "../../images/testing-icon.svg"
import cloudIcon from "../../images/cloud-icon.svg"
import { motion } from "framer-motion"
import { useAnimeOnView } from "../../hooks"
import { colors } from "../../styles"

const AboutContainer = styled.div`
  display: grid;
  grid-template-rows: 4em 3em 225px auto auto;
  grid-template-areas: "spaceTop" "title" "photo" "description" "skills" "spaceBottom";
  grid-row-gap: 20px;
  font-size: 16px;

  @media (min-width: 360px) {
  }
  @media (min-width: 768px) {
    font-size: 18px;
    grid-template-areas: "spaceTop spaceTop" "title title" "description photo" "skills skills" "spaceBottom spaceBottom";
    grid-template-rows: 4em 4em 22em auto auto;
    grid-template-columns: 2fr 1fr;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
    grid-template-columns: 2fr 1fr;
  }
`

const Description = styled(motion.div)`
  grid-area: description;
  margin: 0 auto 1.5em;
  /* text-align: justify; */
  text-justify: inter-word;
  justify-self: center;
  width: 90%;
  opacity: 0;
  max-width: 450px;
  color: ${colors.greyText};

  @media (min-width: 320px) {
  }

  @media (min-width: 768px) {
    margin: 0em;
    padding: 1em;
    justify-self: end;
    align-self: center;
  }
  @media (min-width: 1024px) {
    max-width: 750px;
  }
`

const PhotoContainer = styled(motion.div)`
  grid-area: photo;
  justify-content: center;
  justify-self: center;
  display: flex;

  img {
    object-fit: contain;
  }

  @media (min-width: 768px) {
    display: block;
    justify-self: center;
    align-self: center;
    margin-left: 0em;
  }
  @media (min-width: 1024px) {
    justify-self: start;
  }
`

const SkillsContainer = styled(motion.div)`
  grid-area: skills;
  margin: 0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1350px;
`

/**
 * Welcome Part of the landing page.
 */
export const About = () => {
  const [refDescription, animateDescription] = useAnimeOnView()
  const [refPhotoContainer, animatePhotoContainer] = useAnimeOnView()
  return (
    <Background color={colors.white} id="about">
      <AboutContainer>
        <PageTitle>
          <h3>ABOUT</h3>
        </PageTitle>
        <PhotoContainer
          ref={refPhotoContainer}
          animate={
            animatePhotoContainer
              ? { x: [100, 0], opacity: [0, 1] }
              : { x: 0, opacity: 0 }
          }
          transition={{ duration: 0.5 }}
        >
          <img src={photo} alt="photo" />
        </PhotoContainer>
        <Description
          ref={refDescription}
          animate={
            animateDescription
              ? { x: [-100, 0], opacity: [0, 1] }
              : { x: 0, opacity: 0 }
          }
          transition={{ duration: 0.5 }}
        >
          <p>
            I'm a DevOps Engineer and Full-Stack Web Developer in London, UK.
          </p>
          <p>
            I have spent the last five years working, in multiple projects
            related to the cloud (AWS and Azure), I always enjoy working with
            web technologies, front-end and backend.
          </p>
          <p>
            I am super passionate about productivity and learning.
          </p>
          <p style={{ fontWeight: 700 }}>
            I consider myself a student for life.
          </p>
        </Description>
        <SkillsContainer
          layout
          transition={{
            layoutX: { duration: 0.3 },
            layoutY: { delay: 0.2, duration: 0.3 },
          }}
        >
          <SkillCard
            title="DevOps"
            svgIcon={frontendIcon}
            skills={[
              "CI/CD",
              "Docker",
              "Kubernetes",
              "Terraform",
              "CloudFormation",
              "ARM templates",
              "Ansible",
              "PowerShell DSC",
              "Windows Server",
              "Linux",
              "Networking",
            ]}
          />
          <SkillCard
            title="Languages"
            svgIcon={frontendIcon}
            skills={[
              "Python",
              "Golang",
              "TypeScript",
              "JavaScript",
              "PowerShell",
              "Bash",
            ]}
          />
          <SkillCard
            title="Frontend"
            svgIcon={frontendIcon}
            skills={[
              "React",
              "Redux",
              "Styled-components",
              "Material-UI",
              "HTLM",
              "CSS3",
              "SASS/SCSS",
              "Webpack",
              "Gatsby",
              "Electron",
            ]}
          />
          <SkillCard
            title="Backend"
            svgIcon={backendIcon}
            skills={[
              "Nodejs",
              "SQL",
              "CouchDB",
              "REST APIs",
              "GraphQL",
            ]}
          />
          <SkillCard
            title="Other"
            svgIcon={otherIcon}
            skills={["Git", "Kanban", "Figma", "Scrum"]}
          />
          <SkillCard
            title="Testing"
            svgIcon={testingIcon}
            skills={["Jest", "PowerShell Pester"]}
          />
          <SkillCard
            title="Cloud"
            svgIcon={cloudIcon}
            skills={["AWS", "Azure"]}
          />
        </SkillsContainer>
      </AboutContainer>
    </Background>
  )
}
