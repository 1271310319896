import React from "react"
import styled from "styled-components"
import { Project } from ".."
import creativeHub301 from "../../images/creativehub301.jpg"
import harlist from "../../images/harlist.png"
import ebbin from "../../images/ebbin-editor-min.png"
import { Background, PageTitle, Spacer } from "../shared"
import { colors } from "../../styles"

const ShowcaseContainer = styled.div`
  font-size: 16px;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`

export const Showcase = () => {
  return (
    <Background color={colors.grey} id="showcase">
      <ShowcaseContainer>
        <Spacer height="4em" />
        <PageTitle>
          <h3>SHOWCASE</h3>
        </PageTitle>
        <Spacer height="45px" />
        <Project
          title="Creativehub 301"
          description="A home for professional artists and makers showcasing their new and original work to the general public. (React, Gatsby, styled-components and Sanity as CMS)"
          gitHubLink=""
          projectLink="https://www.creativehub301.com/"
          image={creativeHub301}
        />
        <Project
          title="Ebbin"
          description="Ebbin is a notetaking app that uses modern learning techniques, like spaced repetition and active learning, this is a demo of the text editor that was used for the MVP. (React, Redux, Material-ui, PouchDb and Slatejs) (No 100% mobile friendly)"
          gitHubLink=""
          projectLink="https://main.dvhdg8ppz2qkb.amplifyapp.com/"
          image={ebbin}
        />
        <Spacer height="45px" />
      </ShowcaseContainer>
    </Background>
  )
}
